import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      options: {
            customProperties: true,
        },
      light: {
        primary: '#FF0000',
        secondary: '#4D4D4D',
        green: '#289556',
        red: '#EB0000',
        golden: 'D78729',
        darkBlue: '#020C4F',
        blue: '#6285A7',
        lightBlue: '#27ABC8',
        orange: '#DF570B',
        darkGrey: '#6F6F6F',
        lightGrey: '#ABABAB',
      },
    },
  },
});
