export default {
  data() {
    return {
      web3: null,
      // 表單驗證
      required: (v) => !!v || 'Required',
      listRules: (v) => v.length !== 0 || 'Required',
      TokenAmountRules: [
        (v) => !!v || 'Required',
        (v) => /^[0-9]/.test(v) || 'Number Only',
        (v) => v > 0 || 'Cannot Be Negative',
      ],
      AmountRules: [
        (v) => !!v || 'Required',
        (v) => /^[0-9]/.test(v) || 'Number Only',
        (v) => v > 0 || 'Cannot Be Negative',
        (v) => Number.isInteger(parseFloat(v)) || 'Integer Only',
      ],
      accountRules: [
        (v) => !!v || 'Required',
        (v) => /0x[\w]{40}/.test(v) || 'Please Enter Correct Address',
        (v) => v.length === 42 || 'Please Enter Correct Address',
      ],
      voteRules: [
        (v) => v <= 300 || 'No More Than 300',
        (v) => v >= 10 || 'Must Higher Than 10',
      ],
      TokenAmountRulesNoRequired: [
        (v) => /^[0-9]/.test(v) || 'Number Only',
        (v) => v > 0 || 'Cannot Be Negative',
      ],
      rateRules: [
        (v) => v >= 0.1 || 'Must Higher Than 0.1%',
        (v) => v <= 100 || 'No More Than 100%',
      ],
      phoneRules: (v) => /09[\d]{8}/.test(v) || 'Please Enter Correct Phone Number',
      idRules: (v) => /[\w]{1}[12][\d]{8}/.test(v) || 'Please Enter Correct ID Number',
    };
  },
  computed:{
    shortAddress(){
      return `${this.$store.state.userInfo.address.slice(0, 6)}...${this.$store.state.userInfo.address.slice(38)}`
    }
  },
  methods: {
    timestampToDate(timestamp){
      let time = new Date(timestamp)
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let date = time.getDate()
      return `${year}-${month<10?`0${month}`:month}-${date<10?`0${date}`:date}`
    },
    timestampToTime(timestamp){
      let time = new Date(timestamp)
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let date = time.getDate()
      let hour = time.getHours()
      let min = time.getMinutes()
      let sec = time.getSeconds()

      return `${year}-${month<10?`0${month}`:month}-${date<10?`0${date}`:date} ${hour<10?`0${hour}`:hour}:${min<10?`0${min}`:min}:${sec<10?`0${sec}`:sec}`
    },
    async connectMetamask() {
      let _this = this
      if (window.ethereum){
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(_this.handleAccountsChanged)
          .catch((err) => {
            if (err.code === 4001) {
              // EIP-1193 userRejectedRequest error
              // If this happens, the user rejected the connection request.
              this.$toasted.error('Please connect to MetaMask.');
            } else {
              console.error(err);
            }
          });
      }else{
        this.$toasted.error(this.$t('installMetamask'))
      }
    },
    checkChainId(chainId){
      // console.log('chainId', chainId)
      let isEthereum = chainId === '0x1' || chainId === 1
      // let isEthereum = chainId === '0x4' || chainId === 4

      let isBSC = chainId === '0x38' || chainId === 56
      // let isBSC = chainId === '0x61' || chainId === 97

      this.$store.commit('updateChainId', isBSC);
      this.$store.commit('updateIsEth', isEthereum);
    },
    async sendValidateCode(phone, status){
      let re = /09[\d]{8}/g;
      if (phone.match(re)){
        let data = {
          phone_number: phone.slice(1),
          phone_country_code: phone[0],
          status: status
        }
        let result = await this.$store.dispatch('getValidateCode', data)
        console.log('result', result)
        if (result.status === 731){
          this.$toasted.error(this.$t('sendSuccess'))
        }else if (result.status === 734){
          this.$toasted.error(this.$t('codeNoExpired'))
        }else if (result.status === 676){
          this.$toasted.error(this.$t('accountHasUsed'))
        }else{
          this.$toasted.error(this.$t('sendFail'))
        }
      }else{
        this.$toasted.error(this.$t('pleaseEnterCorrectAccount'))
      }
    },
  },
};
