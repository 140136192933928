export default {
  test: '測試測試測試',
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  // navbar
  myPoint: '我的點數：',
  // index
  login: '登入',
  logout: '登出',
  home: '首頁',
  // login & register
  account: '帳號',
  password: '密碼',
  referer: '推薦人',
  phone: '手機(帳號)',
  validateCode: '驗證碼',
  sendValidateCode: '發送驗證碼',
  name: '姓名',
  idNumber: '身分證字號',
  gender: '性別',
  male: '男',
  female: '女',
  birth: '生日',
  BSCaddress: 'BSC地址',
  email: '電子信箱',
  residentialAddress: '居住地址',
  toRegistry: '前往註冊',
  forgetPw: '忘記密碼',
  newPassword: '新密碼',
  confirm: '確認',
  cancel: '取消',
  edit: '修改',
  // member
  memberCenter: '會員中心',
  memberRefer: '推薦會員',
  myReferCode: '我的推薦碼',
  referLink: '推薦連結',
  myRefers: '我的推薦',
  backMyRefers: '返回我的帳號',
  // store
  storePoints: '儲值點數',
  myPoints: '我的點數',
  myLevel: '我的等級',
  yourAccount: '您的帳號',
  enterTBTAmount: '輸入TBT數量',
  store: '儲值',
  rate: '等級',
  plan: '配套',
  storeReward: '回饋',
  directRefer: '直推',
  algebra: '代數',
  out: '出局',
  toStore: '去儲值',
  storeHistory: '儲值紀錄',
  level: '位階',
  user: '用戶名',
  myStoreHistory: '我的儲值紀錄',
  howToStore: '如何儲值',
  noMoreStoreHistory: '已沒有更多儲值紀錄',
  storeNow: '立即儲值',
  consumpAmount: '消費點',
  // 獎金
  bonus: '獎金系統',
  date: '日期',
  toDetail: '查看明細',
  directReferReward: '直推獎金',
  algebraReward: '代數獎金',
  myVault: '我的小金庫',
  bonusAmount: '獎金點',
  withdrawNow: '立即提領',
  withdrawNowTitle: '立即提領獎金點',
  enterWithdrawAmount: '請輸入欲兌換的獎金點數量',
  bonusWithdrawRate: '（1：1 = 獎金點：TBT點）',
  withdrawTips: [
    '提領確認後',
    '請至TBT中心進行查看'
  ],
  // reward
  reward: '消費回饋',
  contract: '合約',
  from: '於',
  startParticipate: '開始參與',
  participateAmount: '參與數量',
  claimedAmount: '已領數量',
  claimableAmount: '可領數量',
  // admin
  Admin: '後台管理',
  adminManagement: '後台管理系統',
  adminAccount: '管理者帳號',
  adminInfo: '管理者資料',
  withdrawTbt: '提領 TBT',
  // admin login
  loginYourAccount: '登入您的使用者帳號',
  enterAccount: '請輸入帳號',
  enterPassword: '請輸入登入密碼',
  // 提領審核
  "admin-confirm": 'TBT 提領審核',
  confirmApply: 'TBT提領申請審核',
  enterAccountSearch: '輸入帳號搜尋',
  all: '全部',
  agree: '同意',
  confirmYet: '未審核',
  memberAccount: '會員帳號',
  memberNumber: '會員編號',
  walletAddress: '錢包地址',
  applyTime: '申請時間',
  withdrawAmount: '提領金額',
  withdrawResult: '提領結果',
  approver: '審核者',
  approved: '已同意',
  // defi registry
  registryBorrow: '貸款前須先註冊',
  registryDeposit: '投資前須先註冊',
  enterReferer: '請輸入推薦地址',
  // egt
  enterEthAmount: '輸入 ETH 數量',
  enterExchangeEthAmount: '請輸入欲兌換的 ETH 數量',
  enterEgtAmount: '輸入 EGT 數量',
  enterExchangeEgtAmount: '請輸入欲兌換的 EGT 數量',
  enterBSCEgtAmount: '輸入 BSC-EGT 數量',
  canChange: '可兌換成',
  piece: '顆',
  pieceBack: '',
  buy: '購買',
  egtExchangeRateFront: '',
  egtExchangeRateBack: '兌換',
  registryEgt: '購買前須先註冊',
  registry: '註冊',
  sellApprove: '兌換前請先解鎖',
  sell: '兌換',
  sendBscEGT: 'ETH-EGT 發放',
  // bridge
  userAddress: '使用者地址',
  enterBridgeAmount: '請輸入欲發放的 ETH-EGT 數量',
  sendToken: '發放',
  // other
  yourAddress: '您的收款地址',
  backToIndex: '返回首頁',
  priceUpdated: '價格更新於',
  filter: '篩選',
  id: '編號',
  approve: '申請前請先解鎖',
  more: '大於',
  less: '小於',
  noRecord: '無相關紀錄',
  day: '天',
  hour: '時',
  min: '分',
  sec: '秒',
  cannotFindPage: '找不到網頁',
  // calculator
  vs: '兌',
  vsEn: '',
  vsBack: '',
  change: '兌換',
  amount: '數量',
  dollar: '元',
  dataUpdated: '資料更新於',
  calculate: '計算',
  // warning
  warning: '重要提示',
  warningStore: '請確認錢包地址與登入帳號符合，若因錢包地址與登入帳號不符造成儲值錯誤，需自行負擔損失。',
  agreeRule: '我已了解上述說明，同意打勾。',
  // toasted
  loginFirst: '請先登入',
  installMetamask: '請安裝 MetaMask',
  changeMainnet: '請切換到幣安智能鏈',
  changeETH: '請切換至以太主網',
  pleaseEnterCorrectAccount: '請輸入正確帳號',
  sendSuccess: '驗證碼已發送',
  sendFail: '驗證碼發送失敗',
  codeNoExpired: '驗證碼尚未過期，請五分鐘後重試',
  codeHasExpired: '驗證碼已過期，請重新取得',
  codeWrong: '驗證碼錯誤',
  registerSuccess: '註冊成功',
  registerFail: '註冊失敗',
  accountHasUsed: '此帳號已註冊',
  loginSuccess: '登入成功',
  loginFail: '登入失敗',
  passwordNotCorrect: '密碼錯誤',
  accountNotExist: '帳號不存在',
  connectionBreak: 'MetaMask 連接中斷，請重新登入',
  changeWallet: '已切換連接錢包',
  waitApprove: '解鎖中，請稍後',
  waitRegistry: '註冊中，請稍後',
  waitClaim: '提取中，請稍後',
  waitStore: '儲值中，請稍後',
  txSend: '交易已送出',
  userRefuse: '使用者拒絕連線',
  addressHasUsed: '地址錯誤或該地址已註冊',
  copySuccess: '已複製至剪貼簿',
  copyFail: '複製失敗',
  errorOccured: '發生錯誤',
  USDTUnderBalance: 'USDT 餘額不足',
  underBalance: '餘額不足',
  gameOpening: '彩金池開獎中',
  cannotGetGas: '無法計算礦工費',
  renewGetGas: 'WebSocket 連線中斷，請重整網頁以獲取最新的預估礦工費用',
  renew: 'WebSocket 連線中斷，請重整網頁',
  waitGetData: '正在從鏈上獲取資訊，請稍後',
  noReward: '尚無收益可領取',
  cannotGetEthValue: '取得 ETH 市值失敗',
  cannotGetData: '取得資料失敗',
  selectTx: '請選擇要補助之交易',
  txError: '交易發生錯誤',
  agreeFirst: '請勾選同意',
  cannotGetMarketValue: '無法計算市值',
  enterAgain: '請重新輸入',
  resetPwSuccess: '重設密碼成功',
  resetPwFail: '重設密碼失敗',
  resetBscAddressSuccess: '重設 BSC 地址成功',
  resetBscAddressFail: '重設 BSC 地址失敗',
  confirmSuccess: '審核成功',
  confirmFail: '審核失敗',
  withdrawApplySuccess: '申請提領成功',
  withdrawApplyFail: '申請提領失敗',
  // 兌換 
  redeemPage: '兌換頁面',
  redeemTitle: '絕對浪漫',
  redeemImmediately: '立即兌換',
  orderHistory: '訂單紀錄',
  pointHistory: '點數紀錄',
}