<template>
  <div class="index">
    <div class="d-flex justify-center">
      <div class="d-flex flex-column align-center can-click mr-5" @click="toPage('Member-Refer')">
        <img class="mb-1" src="@/assets/img/icon-community.png" width="80px">
        <div>{{ $t('myRefers') }}</div>
      </div>
      <!--<div class="d-flex flex-column align-center can-click mr-5" @click="toPage('Redeem')">
        <img class="mb-1" src="@/assets/img/icon-community.png" width="80px">
        <div>{{ $t('redeemPage') }}</div>
      </div>
      <div class="d-flex flex-column align-center can-click mr-5" @click="toPage('Order')">
        <img class="mb-1" src="@/assets/img/icon-community.png" width="80px">
        <div>訂單紀錄</div>
      </div>
      <div class="d-flex flex-column align-center can-click" @click="toPage('Point')">
        <img class="mb-1" src="@/assets/img/icon-community.png" width="80px">
        <div>點數紀錄</div>
      </div>-->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',

    methods:{
      toPage(name){
        this.$router.push({name: name, params: {lang: this.$store.state.locale}})
      }
    }
  }
</script>
<style lang="scss">
.index{
  padding: 60px;
}
</style>