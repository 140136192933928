<template>
  <v-app id="app" v-if="!$route.path.includes('admin')">
    <v-app-bar
      app
      color="primary"
      dark
      :height="$store.state.nowWidth > 960 ? 77 : 60"
    >
      <div class="d-flex align-center">
        <v-icon
          class="d-md-none d-flex mr-2"
          dark
          @click.stop="phoneNavShow = !phoneNavShow"
        >
          mdi-menu
        </v-icon>
        <!-- desktop -->
        <v-icon
          class="d-none d-md-flex mr-2"
          dark
          @click.stop="desktopSidebarShow = !desktopSidebarShow"
        >
          mdi-menu
        </v-icon>
        <v-img
          alt="logo"
          class="shrink mr-2 can-click"
          contain
          src="@/assets/img/icon-logo.png"
          transition="scale-transition"
          :width="$store.state.nowWidth > 960 ? 40 : 30"
          @click="()=>{if ($route.name!=='Home'){ $router.push({name: 'Home', params: {lang: $store.state.locale}})}}"
        />
        <div class="d-md-block d-none rem-md-13">EGT消費回饋聯盟商城</div>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <div
          class="d-md-flex d-none flex-column justify-center align-center mr-5 can-click"
          v-for="(item, i) in navbar.filter(item=>!item.isFooter)"
          :key="i"
          @click="clickNav(item)"
        >
          <img :src="`${require(`@/assets/img${item.icon}`)}`" width="40px">
          <div class="rem-md-3">
            {{ $t(item.text) }}
          </div>
        </div>
        <template v-if="$store.state.userInfo.identity === 3">
          <div
            class="d-md-flex d-none flex-column justify-center align-center mr-5 can-click"
            v-for="(item, i) in admin.filter(item=>!item.isFooter)"
            :key="i"
            @click="clickNav(item)"
          >
            <img :src="`${require(`@/assets/img${item.icon}`)}`" width="40px">
            <div class="rem-md-3">
              {{ $t(item.text) }}
            </div>
          </div>
        </template>
        <img src="@/assets/img/icon-member-white.svg" width="20px">
        <div v-if="$store.state.userInfo.phone_number">{{ `0${$store.state.userInfo.phone_number}` }}</div>
        <btn :buttonText="$store.state.userInfo.phone_number? `${$t('logout')}` : `${$t('login')}/${$t('registry')}`" :color="'primary'" @clickBtn="log()"></btn>
        <!--<v-menu
          open-on-hover
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-3"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-translate
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in $store.state.langs"
              :key="index"
              class="can-click"
              @click="changeLang(item)"
            >
              <v-list-item-title>{{ $t(item) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>-->
      </div>
    </v-app-bar>

    <v-main id="main" class="pb-15 pb-md-5">
      <router-view/>
    </v-main>

    <v-navigation-drawer
      v-model="desktopSidebarShow"
      fixed
      temporary
      light
      hide-overlay
      class="desktop-sidebar d-none d-md-block pt-5 px-3 rem-12 pb-10"
      style="top: 77px;"
    >
      <v-list dense class="text-center mb-15">
        <router-link
          v-for="item in desktopSidebar"
          :key="item.text"
          :to="{name: `${item.link}`, params: {lang: $store.state.locale}}"
          class="text-decoration-none secondary--text"
          exact
        >
          <div class="mb-5">
            {{ $t(item.text) }}
          </div>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="phoneNavShow"
      fixed
      temporary
      light
      hide-overlay
      class="d-block d-md-none py-5 px-3"
      style="top: 60px;"
    >
      <div class="red--text mb-2" v-if="$store.state.userInfo.phone_number">
        <div class="d-flex align-center mb-2">
          <img class="mr-2" src="@/assets/img/icon-logo-red.png" width="40px">
          <div>0{{ $store.state.userInfo.phone_number }}</div>
        </div>
        
        <div>{{ $t('myPoint') }}</div>
        <div>{{ $store.state.userInfo.balance }} {{ $t('consumpAmount') }}</div>
      </div>
      <v-list dense class="pa-0">
        <router-link
          v-for="item in [...other, ...navbar.filter(item=>!item.isFooter), ...redeemNavs]"
          :key="item.text"
          :to="{name: `${item.link}`, params: {lang: $store.state.locale}}"
          class="text-decoration-none secondary--text"
          exact
        >
          <div>
            {{ $t(item.text) }}
          </div>
        </router-link>
        <template v-if="$store.state.userInfo.identity === 3">
          <router-link
            v-for="item in [...admin.filter(item=>!item.isFooter)]"
            :key="item.text"
            :to="{name: `${item.link}`, params: {lang: $store.state.locale}}"
            class="text-decoration-none secondary--text"
            exact
          >
            <div>
              {{ $t(item.text) }}
            </div>
          </router-link>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-footer
      app
      fixed
      elevation="5"
      color="white"
      class="d-block d-md-none"
      style="z-index: 10;"
    >
      <v-row justify="space-between" align="center">
        <v-col align="center" class="can-click px-0" v-for="(item, i) in navbar" :key="i">
          <router-link :to="{name: `${item.link}`, params: {lang: $store.state.locale}}" class="text-decoration-none">
            <div class="w-full h-full">
              <img :src="`${require(`@/assets/img${item.icon}`)}`" width="30px">
              <div class="rem-2 text-caption black--text">{{ $t(item.text) }}</div>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-footer>

    <v-footer
      app
      fixed
      elevation="5"
      color="primary"
      class="d-none d-md-block"
      style="z-index: 10;"
    >
      <div class="text-center white--text rem-8">絕對網路工程股份有限公司 台北市承德路一段46號6樓</div>
    </v-footer>
  </v-app>

  <v-app id="app-admin" v-else>
    <v-main id="main">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import btn from '@/components/btn.vue';
import base from '@/mixin/base';
export default {
  name: 'App',
  mixins: [base],
  data: () => ({
    phoneNavShow: false,
    desktopSidebarShow: false,
    desktopSidebar: [
      {
        text: '購物商城',
        link: 'Redeem',
      },
      {
        text: '訂單紀錄',
        link: 'Order',
      },
      {
        text: '推薦會員',
        link: 'Member-Refer',
      },
      {
        text: '儲值點數',
        link: 'Store',
      },
      {
        text: '點數紀錄',
        link: 'Point',
      },
      // {
      //   text: '消費回饋',
      //   link: 'Reward',
      // },
      {
        text: '獎金系統',
        link: 'Bonus',
      },
      {
        text: '會員中心',
        link: 'Member',
      },
    ],
    footer: [
      // {
      //   text: `toDeposit`,
      //   link: '/deposit',
      //   icon: '/icon-deposit.png'
      // },
      // {
      //   text: `toBorrow`,
      //   link: '/borrow',
      //   icon: '/icon-borrow.png'
      // },
    ],
    navbar: [
      {
        text: `storePoints`,
        link: 'Store',
        icon: '/icon-store.png',
      },
      // {
      //   text: `reward`,
      //   link: 'Reward',
      //   icon: '/icon-reward.png'
      // },
      {
        text: `home`,
        link: 'Redeem',
        icon: '/icon-home.png',
        isFooter: true,
      },
      {
        text: `bonus`,
        link: 'Bonus',
        icon: '/icon-bonus.png'
      },
      {
        text: `memberCenter`,
        link: 'Member',
        icon: '/icon-member.png',
      },
    ],
    other: [
      {
        text: `memberRefer`,
        link: 'Member-Refer',
        // icon: '/icon-member.png',
      }
    ],
    redeemNavs: [
      {
        text: '購物商城',
        link: 'Redeem',
      },
      {
        text: '訂單紀錄',
        link: 'Order',
      },
      {
        text: '點數紀錄',
        link: 'Point',
      },
    ],
    admin: [
      {
        text: `Admin`,
        link: 'Admin',
        icon: '/icon-member.png',
      },
    ],
    defiContract: null
  }),
  components:{
    btn
  },
  computed:{
    shortAddress(){
      return `${this.$store.state.account.slice(0, 6)}...${this.$store.state.account.slice(38)}`
    }
  },
  methods:{
    async log(){
      if (this.$store.state.account){
        this.$store.commit('clearInfo')
        this.$cookies.remove('address')
        this.$router.push({name: 'Home', params: {lang: this.$store.state.locale}})
      }else{
        this.$store.commit('clearInfo')
        this.$cookies.remove('token')
        this.$router.push({name: 'Login', params: {lang: this.$store.state.locale}})
      }
      // if (this.$store.state.account){
      //   this.$store.commit('clearInfo')
      //   this.$cookies.remove('address')
      //   this.$router.push({name: 'Home'})
      // }else{
      //   if (window.ethereum) {
      //     await this.connectMetamask()
      //   } else {
      //     window.addEventListener('ethereum#initialized', this.connectMetamask, {
      //       once: true,
      //     });

      //     // If the event is not dispatched by the end of the timeout,
      //     // the user probably doesn't have MetaMask installed.
      //     setTimeout(this.connectMetamask, 2000); // 3 seconds
      //   }
      //   this.$forceUpdate()
      // }
    },
    clickNav(item){
      this.$router.push({name: item.link, params: {lang: this.$store.state.locale}})
    },
    changeLang(lang){
      this.$store.commit('updateLang', lang)
      this.$i18n.locale = lang
      this.$router.push({ name: this.$route.name, params: {lang: lang} });
    },
    // async connectMetamask() {
    //   let _this = this
    //   if (window.ethereum){
    //     window.ethereum
    //       .request({ method: 'eth_requestAccounts' })
    //       .then(_this.handleAccountsChanged)
    //       .catch((err) => {
    //         if (err.code === 4001) {
    //           // EIP-1193 userRejectedRequest error
    //           // If this happens, the user rejected the connection request.
    //           this.$toasted.error('Please connect to MetaMask.');
    //         } else {
    //           console.error(err);
    //         }
    //       });
    //   }else{
    //     this.$toasted.error(this.$t('installMetamask'))
    //   }
    // },
    async handleAccountsChanged(accounts){
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        this.$toasted.error('Please connect to MetaMask.');
      } else if (accounts[0] !== this.$store.state.account) {
        this.$store.commit('updateAccount', accounts[0]);
        this.$cookies.set('address', accounts[0]);
        window.location.reload()
      }
    },
    // checkChainId(chainId){
    //   // let isEthereum = chainId === '0x1' || chainId === 1
    //   let isEthereum = chainId === '0x4' || chainId === 4

    //   // let isBsc = chainId === '0x38' || chainId === 56
    //   let isBsc = chainId === '0x61' || chainId === 97
    //   this.$store.commit('updateChainId', isBsc);
    //   this.$store.commit('updateIsEth', isEthereum);
    //   if (!isBsc){
    //     if (this.$route.name !== 'Ethegt-send'){
    //       this.$toasted.error(this.$t('changeMainnet'));
    //     }
    //   }
    // }
  },
  async mounted(){
    // console.log('==========default==========')
    this.$store.commit('updateNowWidth', window.innerWidth)
    window.addEventListener('resize', ()=>{
      this.$store.commit('updateNowWidth', window.innerWidth)
    });
    let _this = this

    if (window.ethereum){
      // metamask disconnect
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length === 0){
          this.$cookies.remove('address')
          this.$store.commit('clearInfo')
          this.$toasted.error(_this.$t('connectionBreak'))
        }else{
          this.$store.commit('updateAccount', accounts[0]);
          this.$cookies.set('address', accounts[0]);
          this.$toasted.show(_this.$t('changeWallet'));
          window.location.reload()
        }
      });

      // get chain id
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      this.checkChainId(chainId)
      window.ethereum.on('chainChanged', _this.checkChainId);

      if (!window.ethereum.selectedAddress){
        let address = this.$cookies.get('address')
        if (address){
          this.$cookies.remove('address')
        }
      }
    }
  }
};
</script>

<style lang="scss">
// layout
#app, #app-admin{
  // .black--text{
  //   color: #4D4D4D !important;
  //   caret-color: #4D4D4D !important;
  // }
  text-overflow: ellipsis;
  cursor: default;
}
#main{
  // margin: 0 0 20px;
}
.desktop-sidebar{
  .v-navigation-drawer__content::-webkit-scrollbar { display: none !important; }
}

.card-wrap{
  padding: 40px 0;
  max-width: 50%;
  @media (max-width: 960px){
    max-width: 100%;
    padding: 40px 20px;
  }
}
.w-full{
  width: 100%;
}
.h-full{
  height: 100%;
}
// common style
.bg-light-gray{
  background-color: #F5F4F4;
}
.red-border{
  border: solid 1px red !important;
}
.text-shadow{
  text-shadow: 0 2px 5px #333;
}
.gas-text{
  font-size: 24px;
}
.can-click{
  cursor: pointer;
}
.toasted.toasted-primary.error{
  background-color: #E53935 !important;
}
.toasted.toasted-primary.default{
  background-color: #FF0000 !important;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.pre-wrap {
  white-space: pre-wrap;
}

// admin
.flow-bg{
  background:
    url('./assets/img/img-flow-1.svg') no-repeat bottom,
    linear-gradient(to right, #27ABC8, #020C4F);
  background-size: contain;
}
.gradient-bg{
  background: linear-gradient(to right, #27ABC8, #020C4F);
}

// other font
.font-10{
  font-size: 0.625rem !important;
}
.font-9{
  font-size: 0.563rem !important;
}
.font-8{
  font-size: 0.5rem !important;
}

// specific style
img[alt="arrow-left"] {
  @include dai_vuetify_md {
    width: 9px;
  }
}
hr.line {
  border-bottom: 1.5px solid #D9D9D9 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}
</style>