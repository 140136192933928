import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);

export default {
  async depositList({ state }) {
    try {
      let result = await Vue.axios.get(`${state.apiUrl}${state.apiVersion}/withdraw_list`, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async confirmDeposit({ state }, data) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/withdraw_check`, data, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
}