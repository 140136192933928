import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import account from './api/account'
import admin from './api/admin'
import redeem from './api/redeem'

Vue.use(Vuex)
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    // url
    webUrl: '',
    apiUrl: '',
    apiVersion: '',
    rpcUrl: '',
    // contract
    TBTConsumpAddress: '',
    // user
    token: '',
    userInfo: {},
    chainId: false,
    isEth: false,
    locale: 'tw',
    langs: ['tw', 'jp', 'en'],
    nowWidth: 0,
    // dialog
    dialogShow: false,
  },
  mutations: {
    updateAccount(state, account){
      state.account = account
    },
    updateToken(state, token){
      state.token = token
    },
    updateUserInfo(state, userInfo){
      state.userInfo = userInfo
    },
    updateNowWidth(state, nowWidth){
      state.nowWidth = nowWidth
    },
    updateChainId(state, chainId){
      state.chainId = chainId
    },
    updateIsEth(state, isEth){
      state.isEth = isEth
    },
    updateLang(state, lang){
      state.locale = lang
    },
    clearInfo(state){
      state.userInfo = {}
      state.token = ''
    },
    updateDialogShow(state, dialogShow){
      state.dialogShow = dialogShow
    },
    setEnv(state, env){
      if (env === 'development'){
        state.webUrl = 'http://localhost:8080'
        // state.apiUrl = 'https://staging-api.tbtconsump.com'
        state.apiUrl = 'https://tbtshop.api-absolute-uv.com'
        state.apiVersion = '/api/v1'
        state.TBTConsumpAddress = '0x7Dd49B4f2577eE9BBC1EAFAE4D21e2555D7e84bf'
        // state.TBTBankAddress = '0x852f377F73b9d375F722AD39789415903DfF4cF6'
        state.TBTAddress = '0xDDB982Ab460F6E20c0cA217EEc88cCA0512172e8'
        state.rpcUrl = 'https://rinkeby.infura.io/v3/80d11963e8f5426d896589e98c287c95'
      }else{
        state.webUrl = 'https://tbtconsump.com'
        state.apiUrl = 'https://tbtshop.api-absolute-uv.com'
        state.apiVersion = '/api/v1'
        state.TBTConsumpAddress = '0xF7AaFEb15e6134108320582f80a221a19D441237'
        // state.TBTBankAddress = '0x76870a49E2d5cB24749Acf29AB21c3DF76c0F66B'
        state.TBTAddress = '0xd7F97f2cBcDC696484dA7C04AD012723392Ce30B'
        state.rpcUrl = 'https://bsc.getblock.io/mainnet/15db4d71-9556-49be-a956-ae738babec27/'
      }
    }
  },
  getters: {
    getIsCorrectChain(state){
      return process.env.NODE_ENV === 'development' ? state.isEth : state.chainId
    }
  },
  actions: {
    async getTokenPrice({ state }, data){
      console.log('state', state.chainId)
      let result = await Vue.axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${data.token}&vs_currencies=${data.currency}&include_last_updated_at=true`)
      return result.data
    },
    ...account,
    ...admin,
    ...redeem,
  },
  modules: {
  }
})
