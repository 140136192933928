import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import app from '../main'
import Home from '../views/Home.vue'
const cookies = require('vue-cookies')
Vue.use(VueRouter)
Vue.use(cookies);

const routes = [
  {
    path: '/:lang',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: false },
  },
  {
    path: '/:lang/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/:lang/register',
    name: 'Registry',
    component: () => import('../views/Registry.vue'),
    meta: { requiresAuth: false },
  },
  // 會員中心
  {
    path: '/:lang/member',
    name: 'Member',
    component: () => import('../views/Member/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/member/refer',
    name: 'Member-Refer',
    component: () => import('../views/Member/Refer/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/member/refer/list',
    name: 'Member-Refer-List',
    component: () => import('../views/Member/Refer/List.vue'),
    meta: { requiresAuth: true },
  },
  // store
  {
    path: '/:lang/store',
    name: 'Store',
    component: () => import('../views/Store/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/store/pay',
    name: 'Store-Pay',
    component: () => import('../views/Store/Pay.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/store/history',
    name: 'Store-History',
    component: () => import('../views/Store/History.vue'),
    meta: { requiresAuth: true },
  },
  // Bonus
  {
    path: '/:lang/bonus',
    name: 'Bonus',
    component: () => import('../views/Bonus/index.vue'),
    meta: { requiresAuth: true },
  },
  // // Reward
  // {
  //   path: '/:lang/reward',
  //   name: 'Reward',
  //   component: () => import('../views/Reward/index.vue'),
  //   meta: { requiresAuth: true },
  // },
  // Redeem
  {
    path: '/:lang/redeem',
    name: 'Redeem',
    component: () => import('../views/Redeem/index.vue'),
    meta: { requiresAuth: false },
  },
  // Order
  {
    path: '/:lang/order',
    name: 'Order',
    component: () => import('../views/Order/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/order/:id',
    name: 'Order-Id',
    component: () => import('../views/Order/_id.vue'),
    meta: { requiresAuth: true },
  },
  // Point
  {
    path: '/:lang/point',
    name: 'Point',
    component: () => import('../views/Point/index.vue'),
    meta: { requiresAuth: true },
  },
  
  // admin login
  {
    path: '/:lang/admin/login',
    name: 'admin-login',
    component: () => import('../views/Admin/Login.vue'),
    meta: { requiresAuth: true },
  },

  // admin
  {
    path: '/:lang/admin',
    component: () => import('../views/Admin/index.vue'),
    name: "Admin",
    children: [
      // {
      //   // UserProfile will be rendered inside User's <router-view>
      //   // when /user/:id/profile is matched
      //   path: 'login',
      //   name: 'admin-login',
      //   component: () => import('../views/Admin/Login.vue'),
      // },
      {
        path: 'deposit-confirmation',
        name: 'admin-confirm',
        component: () => import('../views/Admin/Confirm.vue'),
      },
      {
        path: 'withdraw-tbt',
        name: 'withdraw-tbt',
        component: () => import('../views/Admin/WithdrawTbt.vue'),
      },
    ],
    meta: { requiresAuth: true },
  },
  
  {
    // will match everything
    path: '*',
    component: () => import('../views/Other.vue'),
    meta: { requiresAuth: false },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // console.log('to.params.lang', to.params.lang)
  if (!to.params.lang || !store.state.langs.includes(to.params.lang)){
    next('/tw')
  }

  // Set envionment variable
  await store.commit('setEnv', process.env.NODE_ENV)

  // Get user info from cookies
  try{
    let token = Vue.$cookies.get('token')
    if (token != undefined) {
      if ( store.state.token === '' ){
        try{
          await store.commit('updateToken', token)
          await store.dispatch('getUserInfo')
        }catch(error){
          console.log('error', error)
          store.commit('clearInfo')
          Vue.$cookies.remove('token')
        }
      }
    }
  }catch(error){
    console.log('error', error)
    next();
  }

  // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
  if (to.matched.some(record => {
    // console.log(record.name, record.meta.requiresAuth);
    return record.meta.requiresAuth;
  })) {
    // 未登入
    if (!store.state.userInfo.phone_number){
      try{
        next({ name: 'Login' });
      }catch(error){
        console.log('error', error)
      }
    }else{
      next(); // 往下繼續執行
    }
  } else {
    next(); // 往下繼續執行
  }
});

router.afterEach(async (to) => {
  // Get lang from route path
  if (to.params.lang){
    store.commit('updateLang', to.params.lang)
    app.$i18n.locale = to.params.lang
  }
});


export default router
