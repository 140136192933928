export default {
  test: 'test test test',
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  // navbar
  gamePool: 'プール',
  buyEGT: 'EGT購入',
  sellEGT: 'ETHへ両替',
  swapEGT: 'ETH-EGTへ両替',
  calculator: 'EGT換算機',
  community: 'My community',
  // index
  indexTitle: 'スマートコントラクト・レンディングの商用アプリケーション',
  indexSubtitle: 'スマートコントラクト・分散型・ETH連動・P2Pレンディング',
  indexDesc: 'EDTは、スマートコントラクトとETHを連動させた完全分散型のブロックチェーンP2Pレンディング商用アプリケーションであり、公平、公正、公開を実現し、誰もが安心して、気軽に投資や融資に参加することができます。',
  investAmount: '融資額',
  gamePoolAmount: 'プール金額',
  login: 'ログイン',
  logout: 'ログアウト',
  // 貸款
  toBorrow: '融資申請',
  borrowRule: '融資規定',
  borrowRuleText: [
    '借り手は、ブロックチェーン・ウォレットに担保を預けてから、申請フォームに記入して融資申請を行います。',
    '申請が成功すると、担保は保管のためにスマートコントラクトに転送され、融資契約は「My Loan」と「投資リスト」に表示されます。マッチングが成功するまでの間、いつでも内容を変更することができます。',
    'マッチングが成功すると、借り手のブロックチェーン・ウォレットは利息を差し引いた融資額を受け取り、融資契約は「マイローン」に表示されます。',
    '借り手は、融資契約の期限内であればいつでも返済することができます。 返済が完了すると、スマートコントラクトは自動的に借り手のブロックチェーン・ウォレットに担保を返却します。',
    '借り手が融資契約満了時に返済を行わなかった場合、担保はマートコントラクトにより没収され、その所有権は融資契約の投資家に帰属するものとします。',
    '上記操作に伴うBNBマイナー料は、借り手の負担とします。'
  ],
  myLoans: 'My Loan',
  createLoan: '融資申請',
  // 投資
  toDeposit: '投資',
  depositRule: '投資規則',
  depositRuleText: [
    '投資家は、投資する前にブロックチェーン・ウォレットにUSDTを入金し、投資リストで投資契約を検索して投資を申請する必要があります。',
    '投資が成功すると、USDTは自動的に貸し手に貸し出され、投資契約は「My Investment」に表示されます。',
    '投資契約の満了前に、貸し手が返済をした場合、貸し出されたお金と利息が自動的に投資家のブロックチェーン・ウォレットに送金されます。',
    '投資契約満了時に、貸し手が返済を行わなかった場合、担保の90％が投資家に、10％がプラットフォームに渡り、投資家は投資契約に基づく担保と利息の返還を請求することができます。',
    '上記操作に伴うBNBのマイナー料は投資家の負担とします。'
  ],
  myDeposit: 'My Investment',
  orderList: '投資リスト',
  // defi registry
  registryBorrow: '事前登録',
  registryDeposit: '事前登録',
  enterReferer: '紹介者アドレス入力',
  // egt
  enterEthAmount: 'ETH 数量の入力',
  enterExchangeEthAmount: '両替したい ETH 数量を入力してください',
  enterEgtAmount: 'EGT 数量の入力',
  enterExchangeEgtAmount: '両替したい EGT 数量を入力してください',
  enterBSCEgtAmount: 'BSC-EGT 数量の入力',
  canChange: '',
  piece: '個の',
  pieceBack: 'と両替可能',
  buy: '購入',
  egtExchangeRateFront: '',
  egtExchangeRateBack: '換算',
  registryEgt: '事前登録',
  registry: '登録',
  sellApprove: 'ロックを解除してからご利用ください',
  sell: '両替',
  sendBscEGT: 'ETH-EGT 放出',
  // bridge
  userAddress: 'ユーザーアドレス',
  enterBridgeAmount: '放出したい ETH-EGT 數量を入力してください',
  sendToken: '放出',
  // game
  luckyPool: 'ラッキプール',
  countdown: '賞金カウントダウン',
  noOrder: '現在、注文はありません',
  drawing: '賞金中',
  currRound: '現在のラウンド',
  newLuckyAddress: '最新のラッキーアドレス',
  luckyPoolRule: 'ラッキプールの規則',
  luckyPoolRuleText: [
    'ラウンドの終了時間は、上記の「賞金カウントダウン」に基づき、ラッキー賞金の累計と賞金カウントダウンの時間は、下記の「賞金プール累計表」と連動しています。新たな融資契約が成立した場合、賞金カウントダウンはラッキー賞金の対応時間に応じて再計算されます。賞金カウントダウン終了時に、新たな融資契約が成立していない場合、そのラウンドは終了し、ラッキーアドレスがすべてのラッキー賞金を受け取ることになります。',
    'ラッキーアドレス：賞金カウントダウン終了時に融資契約のマッチングに成功した最後の貸し手。\n例）累計ラッキー賞金が100,000を超え、対応時間を10分とした場合。\nAさんが融資契約（融資額・支払利息無制限）のマッチングに成功すると、Aさんが最新のラッキーアドレスとなり、10分の賞金カウントダウンが始まります。新たな融資契約のマッチング成功者が出ないまま賞金カウントダウンが終了すると、Aさんがラッキーアドレスとなり、単独でラッキー賞金を受け取ることができます。賞金カウントダウンの終了前に、Bさんが融資契約のマッチングに成功した場合、そこから再び10分の賞金カウントダウンが始まり、BさんがAさんに代わり上記のようなラッキーアドレスとなります。'
  ],
  stepTable: '賞金プール累計表',
  time: '時間',
  blockchainBased: 'ブロックチェーンの時間基づく',
  accumPoolAmount: '累計賞金',
  rankPool: '競争賞金プール',
  myRankAmount: '自分の競争金額',
  rankPoolRule: '競争賞金プールの規則',
  rankPoolRuleText: [
    '競争賞金プールは30日ごとのラウンドで、ラウンドの終了は上記の「賞金カウントダウン」に基づきます。ラウンド終了時に競争金額が最も多い優勝者が競争賞金プールの50％を獲得し、2位から50位までの入賞者がその比率に応じて競争賞金プールの50％を分け合います。',
    '競争金額：同じラウンドで融資申請をし、マッチング成功後に支払われた累計利息のことで、この金額を次のラウンドに繰り越すことはできません。\n例）同じラウンドで、Aさんが初めて融資を申請し、マッチング成功時に支払う利息が100USDT、2回目の融資申請をし、マッチング成功時に支払う利息が200USDTだった場合、そのラウンドでのAさんの競争金額は100+200=300となります。ラウンド終了時にAさんが競争金額が最も多い優勝者であった場合、競争賞金プールの50％を獲得します。ラウンド終了時に、Aさんが2位から50位までの入賞者に入っていた場合、Aさんはその比率に応じて競争賞金プールの50％を分け合うことになります。'
  ],
  rank: 'ランキング',
  address: 'アドレス',
  rankAmount: '金額',
  // other
  yourAddress: '支払い先アドレス',
  backToIndex: 'Homeへ',
  gasNowEstimate: '現在のマイナー料試算',
  gasWarning: '(この試算はあくまで参考であり、実際のコストはコントラクト実行時の消費に左右されます。)',
  priceUpdated: 'に更新された価格',
  filter: '検索フィルター',
  id: '番号',
  loanDays: '融資日数',
  loanToken: '担保の通貨',
  loanTokenAmount: '担保数',
  loanAmount: '融資金額',
  loanMortgage: '融資比率',
  loanRate: '利率',
  loanInterest: '利息',
  marketValue: '市場価値',
  APR: '年間収益率',
  waiting: 'マッチング待ち',
  repay: '返済済み',
  breach: '違約',
  loaning: '融資中',
  approve: '申請前にロックを解除してください。',
  more: '大なり',
  less: '小なり',
  invest: '投資',
  noRecord: '該当する記録はありません',
  day: '日',
  hour: '時間',
  min: '分',
  sec: '秒',
  contract: '契約',
  expired: '期限切れ',
  buffer: 'の緩衝期間',
  due: '満了',
  status: '契約状況',
  withdraw: '回収',
  edit: '編集',
  cancel: 'キャンセル',
  payback: '返済',
  apply: '申請',
  editWarning: '編集は注文を【キャンセル】して、再度新しい注文を【作成】してください',
  editSubWarning: '(キャンセルや作成には、マイナー料がかかかります)',
  // calculator
  vs: 'から',
  vsEn: '',
  vsBack: 'へ両替',
  change: '兌換',
  amount: 'の数量',
  dollar: '元',
  dataUpdated: 'に更新された情報',
  calculate: '計算',
  // community
  refererAmount: '直紹介の人数',
  communityAmount: 'マイコミュニティの人数',
  // ADT
  adt: 'ADT 天使分配',
  holding: 'ホールド量',
  claimable: '受取可能量',
  claim: '受取',
  // gas fee
  gasPage: 'Gas 補助',
  date: '日付',
  from: 'から',
  to: 'まで',
  applyAddress: '申請人アドレス',
  search: '検索',
  total: '合計',
  subsidy: '補助金',
  subsidyTotal: '補助総額',
  send: '送信',
  // warning
  warning: '重要なお知らせ',
  warningRegistry: '1. 事前にご登録ください。登録されていない場合、取引は失敗となります。',
  warningBorrow: '2. 融資リストに掲載されている担保の市場価格は、あくまでも参考であり、借り手と貸し手いずれの当事者も意思決定の唯一の根拠として依拠してはなりません。このプラットフォームは、担保の正しい市場価値を通知する責任を負いません。',
  warningDeposit: '2. 投資リストに掲載されている担保の市場価値は、あくまでも参考であり、借り手と貸し手いずれの当事者も意思決定の唯一の根拠として依拠してはなりません。このプラットフォームは、担保の正しい市場価値を通知する責任を負いません。',
  warningCalculate: '担保の時価は「EGT換算機」で計算してください。',
  toRegistry: '登録へ',
  toCalculator: 'EGT換算機へ',
  agree: '上記の説明を理解し、チェックを入れることに同意します。',
  confirm: '確認',
  // toasted
  loginFirst: 'はじめにログインしてください',
  installMetamask: 'MetaMaskをインストールしてください',
  changeMainnet: 'イーサメインネットワークに切り替えてください',
  changeETH: 'イーサメインネットワークに切り替えてください',
  connectionBreak: 'MetaMask接続が中断されました。再度ログインしてください',
  changeWallet: 'ウォレットへ切り替え済み',
  waitApprove: 'ロック解除中です、お待ちください',
  waitRegistry: '登録中です、お待ちください',
  waitClaim: '引き出し中です、お待ちください',
  txSend: '取引が送信されました',
  userRefuse: 'ユーザーによる接続拒否',
  addressHasUsed: 'このアドレスは登録されています',
  errorOccured: 'エラーが発生しました',
  USDTUnderBalance: 'USDTの残高が不足しています',
  underBalance: '残高が不足しています',
  gameOpening: 'プール賞金中',
  cannotGetGas: 'マイナー料を計算できません',
  renewGetGas: 'WebSocket接続が中断されました。最新の概算マイナー料を取得するためには、ページを更新してくだい',
  renew: 'WebSocket接続が中断されました。ページを更新してくだい',
  waitGetData: 'チェーンから情報を取得していますので、しばらくお待ちください',
  noReward: '受取可能な収益はありません',
  cannotGetEthValue: 'ETH市場価格を取得できませんでした',
  cannotGetData: 'データの取得に失敗しました',
  selectTx: '補助対象となる取引を選択してください',
  txError: '取引にエラーが発生しました',
  agreeFirst: '同意する場合はチェックを入れてください。',
  cannotGetMarketValue: '市場価値を算出できません',
}