import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);

export default {
  async getValidateCode({ state }, data) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/verification_code`, data)
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async register({ state }, data) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/register`, data)
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async login({ state }, data) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/login`, data)
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async resetPw({ state }, data) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/reset_password`, data)
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async resetBscAddress({ state }, data) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/reset_address`, data)
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async getUserInfo({ state, commit }) {
    try {
      let result = await Vue.axios.get(`${state.apiUrl}${state.apiVersion}/user_data`, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      console.log('result', result)
      if (result.data.status === 672){
        commit('updateUserInfo', result.data.data)
      }else{
        commit('updateUserInfo', {})
      }
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async referHistory({ state }, type) {
    try {
      let result = await Vue.axios.get(`${state.apiUrl}${state.apiVersion}/referrer_history/${type}`, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async referList({ state }, id) {
    try {
      let result = await Vue.axios.get(`${state.apiUrl}${state.apiVersion}/referrer_list/${id}`, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async withdrawBonus({ state }, data) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/create_withdraw`, data, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
}