import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);

export default {
  async getProductList({ state }) {
    try {
      let result = await Vue.axios.get(`${state.apiUrl}${state.apiVersion}/all_product`)
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async redeemProduct({ state }, data) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/buy_product`, data, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async getRedeemOrder({ state }) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/user_orders`, {}, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async getRedeemOrderDetail({ state }, data) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/order_detail`, data, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async claimProducts({ state }, data) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/exchange_product`, data, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
  async getPointHistory({ state }) {
    try {
      let result = await Vue.axios.post(`${state.apiUrl}${state.apiVersion}/point_history`, {}, {
        headers:{
          Authorization: `Bearer ${state.token}`
        }
      })
      return result.data
    } catch (error) {
      console.log('error', error)
    }
  },
}